import React from "react"
import { graphql } from "gatsby"
import { get } from "lodash"
import DiscoverScreen from "../screens/DiscoverScreen/DiscoverScreen"
import SiteLayout from "../components/SiteLayout/SiteLayout"
import SiteWrapper from "../components/SiteWrapper/SiteWrapper"
import { ICoachPreviewGraph } from "../data/cms/coachPreview"
import { IDiscoverScreenGraph } from "../data/cms/discover"
import { COACH_ENTRY_POINTS } from "../screens/CoachScreen/CoachScreen"
import PageLocation from "../analytics/components/PageLocation/PageLocation"
import { getEnvKey } from "../utils/env"
import { ROUTES } from "../navigation/routes"
import PageMetaTags, {
  CMSWebMetaSocial,
} from "../components/SiteMeta/PageMetaTags/PageMetaTags"

interface Data extends CMSWebMetaSocial {
  allDatoCmsCoachDev?: {
    edges: {
      node: ICoachPreviewGraph
    }[]
  }
  allDatoCmsCoachStg?: {
    edges: {
      node: ICoachPreviewGraph
    }[]
  }
  allDatoCmsCoachUat?: {
    edges: {
      node: ICoachPreviewGraph
    }[]
  }
  allDatoCmsCoachPrd?: {
    edges: {
      node: ICoachPreviewGraph
    }[]
  }
  datoCmsWebDiscoverHomeScreenDev?: IDiscoverScreenGraph
  datoCmsWebDiscoverHomeScreenStg?: IDiscoverScreenGraph
  datoCmsWebDiscoverHomeScreen?: IDiscoverScreenGraph
  datoCmsWebDiscoverHomeScreenPrd?: IDiscoverScreenGraph
}

interface Props {
  data: Data
}

const getCoaches = (props: Props): ICoachPreviewGraph[] => {
  const key = getEnvKey({
    devKey: "allDatoCmsCoachDev",
    stgKey: "allDatoCmsCoachStg",
    uatKey: "allDatoCmsCoachUat",
    prdKey: "allDatoCmsCoachPrd",
    defaultKey: "allDatoCmsCoachStg",
  })

  const edges: {
    node: ICoachPreviewGraph
  }[] = get(props, `data.${key}.edges`, [])
  return edges.map(({ node }) => {
    return node as ICoachPreviewGraph
  })
}

const DiscoverPage: React.FC<Props> = props => {
  const coaches = getCoaches(props)
  const contentKey = getEnvKey({
    devKey: "datoCmsWebDiscoverHomeScreenDev",
    stgKey: "datoCmsWebDiscoverHomeScreenStg",
    uatKey: "datoCmsWebDiscoverHomeScreen",
    prdKey: "datoCmsWebDiscoverHomeScreenPrd",
    defaultKey: "datoCmsWebDiscoverHomeScreen",
  })
  const discover: IDiscoverScreenGraph = get(props, `data.${contentKey}`)
  return (
    <SiteWrapper>
      <SiteLayout>
        <PageLocation
          coachEntryLocation={COACH_ENTRY_POINTS.Discover}
          pageName={ROUTES.discover.name}
        >
          <PageMetaTags data={props} />
          <DiscoverScreen coaches={coaches} content={discover} />
        </PageLocation>
      </SiteLayout>
    </SiteWrapper>
  )
}

export default DiscoverPage

export const query = graphql`
  query DiscoverScreenQuery(
    $devEnv: Boolean = false
    $stgEnv: Boolean = false
    $uatEnv: Boolean = false
    $prdEnv: Boolean = false
  ) {
    allDatoCmsCoachStg @include(if: $stgEnv) {
      edges {
        node {
          ...CoachPreview
        }
      }
    }
    allDatoCmsCoachDev @include(if: $devEnv) {
      edges {
        node {
          ...DevCoachPreview
        }
      }
    }
    allDatoCmsCoachUat @include(if: $uatEnv) {
      edges {
        node {
          ...UatCoachPreview
        }
      }
    }
    allDatoCmsCoachPrd @include(if: $prdEnv) {
      edges {
        node {
          ...PrdCoachPreview
        }
      }
    }
    datoCmsWebDiscoverHomeScreenDev @include(if: $devEnv) {
      ...DiscoverScreenDev
      filterTopics {
        featuredCoaches {
          ...DevCoachPreview
        }
      }
    }
    datoCmsWebDiscoverHomeScreenStg @include(if: $stgEnv) {
      ...DiscoverScreenStg
      filterTopics {
        featuredCoaches {
          ...CoachPreview
        }
      }
    }
    datoCmsWebDiscoverHomeScreenPrd @include(if: $prdEnv) {
      ...DiscoverScreenPrd
      filterTopics {
        featuredCoaches {
          ...PrdCoachPreview
        }
      }
    }
    datoCmsWebDiscoverHomeScreen @include(if: $uatEnv) {
      ...DiscoverScreen
      filterTopics {
        uatFeaturedCoaches {
          ...UatCoachPreview
        }
      }
    }
    datoCmsWebMetaSocialDev @include(if: $devEnv) {
      screenMetaTags: discoverScreenMetaTags {
        ...PageMeta
      }
    }
    datoCmsWebMetaSocialStg @include(if: $stgEnv) {
      screenMetaTags: discoverScreenMetaTags {
        ...PageMeta
      }
    }
    datoCmsWebMetaSocial @include(if: $uatEnv) {
      screenMetaTags: discoverScreenMetaTags {
        ...PageMeta
      }
    }
    datoCmsWebMetaSocialPrd @include(if: $prdEnv) {
      screenMetaTags: discoverScreenMetaTags {
        ...PageMeta
      }
    }
  }
`
