import React  from "react"
import styled from "styled-components"
import { responsiveScrollableContainerCss } from "../../../../ui/layout"
import theme from "../../../../ui/theme"
import { DESKTOP_BREAKPOINT } from "../../../../ui/responsive"

const StyledContainer = styled.ul`
  line-height: 1;
  ${responsiveScrollableContainerCss};

  > li {
    &:not(:first-child) {
      margin-left: 20px;

      ${DESKTOP_BREAKPOINT} {
        margin-left: 36px;
      }
    }
  }
`

const StyledTag = styled.button<{
  active: boolean
}>`
  cursor: pointer;
  font-weight: ${theme.fontWeights.bold};
  font-size: ${theme.fontSizes.mediumPlus}px;
  line-height: 30px;
  color: ${props => (props.active ? theme.colors.darkGrey : "#D4D4D4")};
`

export const TOPIC_ALL = "All"

const DiscoverKeywords: React.FC<{
  selectedTopic: string
  topics: string[]
  onSelect: (arg0: string) => void
}> = ({ selectedTopic, onSelect, topics }) => {
  return (
    <StyledContainer>
      {[TOPIC_ALL, ...topics].map(topic => {
        return (
          <li key={topic}>
            <StyledTag
              active={topic === selectedTopic}
              onClick={() => {
                onSelect(topic)
              }}
            >
              {topic}
            </StyledTag>
          </li>
        )
      })}
    </StyledContainer>
  )
}

export default DiscoverKeywords
