import React, { useMemo, useState } from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import ReactMarkdown from "react-markdown"
import { useMixpanel } from "gatsby-plugin-mixpanel"
import { ResponsiveMaxWidthContent } from "../../ui/components/MaxWidthContent/MaxWidthContent"
import { bgImageCss, desktopGridCss, mobileGridCss } from "../../ui/shared"
import {
  getCoachId,
  getCoachImage,
  getCoachName,
  getCoachPreviewPrice,
  getCoachPreviewSlug,
  isPlaceholder,
  ICoachPreviewGraph,
} from "../../data/cms/coachPreview"
import { ROUTES } from "../../navigation/routes"
import theme from "../../ui/theme"
import DiscoverKeywords, {
  TOPIC_ALL,
} from "./components/DiscoverKeywords/DiscoverKeywords"
import { placeholderCss } from "../../ui/helpers"
import {
  DESKTOP_BREAKPOINT,
  MOBILE_BREAKPOINT,
  showOnDesktopCss,
  StyledDesktopOnly,
} from "../../ui/responsive"
import {
  getDiscoverDesktopDescription,
  getDiscoverNoResultsCopy,
  getDiscoverTopics,
  getTopicFeaturedCoaches,
  IDiscoverScreenGraph,
} from "../../data/cms/discover"
import { minHeightCss } from "../SignUpScreen/views/InfoStep/InfoStep"
import {
  fancyCondensedFontCss,
  fancyWideFontCss,
} from "../../ui/typography/fonts"
import { gaEventSearchSearch } from "../../analytics/google"
import { mixpanelEventSearch } from "../../analytics/mixpanel"
import { useUserUid } from "../../auth/components/AuthHandler/AuthHandler"
import { YouspiredFooterCTA } from "../../components/AboutYouspired/AboutYouspired"
import { useOnCoachClick } from "../../navigation/hooks"
import PriceText from "../../ui/components/PriceText/PriceText"

const StyledContainer = styled.div`
  ${minHeightCss};
`

const StyledHeader = styled.header`
  padding-top: 42px;
  margin-bottom: 27px;

  ${DESKTOP_BREAKPOINT} {
    ${desktopGridCss};
    margin-top: 89px;
    margin-bottom: 68px;
  }
`

const StyledHeaderContent = styled.div`
  ${DESKTOP_BREAKPOINT} {
    grid-column: span 3;
  }
`

const StyledDesktopContent = styled(StyledDesktopOnly)`
  grid-column: span 3;
  font-size: ${theme.fontSizes.medium}px;
  max-width: 469px;
`

const StyledHeading = styled.h2`
  ${fancyCondensedFontCss};
  font-size: 38px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 36px;
  text-transform: uppercase;
  margin-bottom: 14px;

  ${DESKTOP_BREAKPOINT} {
    ${fancyWideFontCss};
    font-size: 80px;
    font-weight: 900;
    line-height: 43.2px;
    color: ${theme.colors.secondary};
    margin-bottom: 53px;
  }

  span {
    ${fancyWideFontCss};
    color: ${theme.colors.primary};

    ${MOBILE_BREAKPOINT} {
      font-size: 38px;
    }
  }
`

const StyledSearchContainer = styled.div`
  position: relative;

  &::after {
    content: "";
    position: absolute;
    left: 13px;
    top: 6px;
    bottom: 6px;
    width: 22px;
    pointer-events: none;
    background-image: url(/svgs/search.svg);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: left center;

    ${DESKTOP_BREAKPOINT} {
      left: 17px;
      width: 27px;
    }
  }
`

const StyledSearchInput = styled.input`
  background: #efefef;
  border-radius: 24px;
  font-size: 16px;
  line-height: 27px;
  display: block;
  padding: 7px 10px 6px 43px;
  width: 100%;

  ${DESKTOP_BREAKPOINT} {
    font-weight: ${theme.fontWeights.bold};
    font-size: ${theme.fontSizes.mediumPlus}px;
    line-height: 30px;
    padding: 15px 15px 15px 53px;
    border-radius: 36px;
  }

  ${placeholderCss(`
      color: ${theme.colors.lightGrey};
  `)};
`

const StyledResultsContainer = styled(ResponsiveMaxWidthContent)`
  margin-top: 19px;
  padding-bottom: 60px;

  ${MOBILE_BREAKPOINT} {
    ${mobileGridCss};
    grid-row-gap: 32px;
  }

  ${DESKTOP_BREAKPOINT} {
    margin-top: 40px;
    padding-bottom: 130px;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-column-gap: 20px;
    grid-row-gap: 40px;
  }
`

const StyledCoachWrapper = styled.div`
  ${MOBILE_BREAKPOINT} {
    grid-column: span 2;
  }
`

const StyledCoachImage = styled.div<{
  image: string
}>`
  ${bgImageCss};
  border-radius: ${theme.borderRadius.common}px;
  height: 225px;
  background-image: url(${props => props.image});
  margin-bottom: 4px;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.11);
  position: relative;

  ${DESKTOP_BREAKPOINT} {
    height: 411px;
  }
`

const StyledCoachPrice = styled.div`
  ${showOnDesktopCss};
  position: absolute;
  top: 16px;
  right: 14px;
  border-radius: 4px;
  background: ${theme.colors.secondary};
  color: ${theme.colors.white};
  font-size: ${theme.fontSizes.smallish}px;
  line-height: ${theme.lineHeights.smallish}px;
  font-weight: ${theme.fontWeights.bold};
  padding: 8px 12px;
`

const StyledCoachName = styled.div`
  font-size: ${theme.fontSizes.medium}px;
  font-weight: ${theme.fontWeights.bold};
`

const StyledLogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding-bottom: 70px;
  img {
    max-width: 223px;
    line-height: 0;
  }
`

const StyledNoResultsCopy = styled.div`
  font-size: ${theme.fontSizes.medium}px;
  grid-column: span 2;
  max-width: 300px;

  ${MOBILE_BREAKPOINT} {
    grid-column: span 4;
  }

  h2,
  h3 {
    font-size: ${theme.fontSizes.large}px;
    line-height: ${theme.lineHeights.mediumPlus}px;
    font-weight: ${theme.fontWeights.bold};
  }

  p {
    margin-top: 16px;
  }
`

const DiscoverScreen: React.FC<{
  coaches: ICoachPreviewGraph[]
  content: IDiscoverScreenGraph
}> = ({ coaches, content }) => {
  const mixpanel = useMixpanel()
  const uid = useUserUid()
  const topics = getDiscoverTopics(content)
  const [searchInput, setSearchInput] = useState("")
  const [selectedTopic, setSelectedTopic] = useState(TOPIC_ALL)
  const topicCoaches: ICoachPreviewGraph[] = useMemo(() => {
    if (selectedTopic === TOPIC_ALL) {
      return coaches
    }
    const topic = topics.find(findTopic => {
      return findTopic.topic === selectedTopic
    })
    if (!topic) return []
    return getTopicFeaturedCoaches(topic)
  }, [selectedTopic])
  const filteredCoaches: ICoachPreviewGraph[] = useMemo(() => {
    return topicCoaches.filter(coach => {
      return coach.name.toLowerCase().includes(searchInput.toLowerCase())
    })
  }, [searchInput, topicCoaches])

  const onBlur = () => {
    if (searchInput) {
      mixpanelEventSearch(mixpanel, searchInput, filteredCoaches.length, uid)
      gaEventSearchSearch(searchInput)
    }
  }

  const onCoachClick = useOnCoachClick()

  return (
    <StyledContainer>
      <ResponsiveMaxWidthContent>
        <StyledHeader>
          <StyledHeaderContent>
            <StyledHeading>
              Discover<span>.</span>
            </StyledHeading>
            {/* <StyledSearchContainer> */}
            {/*  <StyledSearchInput */}
            {/*    value={searchInput} */}
            {/*    onChange={event => setSearchInput(event.target.value)} */}
            {/*    onBlur={onBlur} */}
            {/*    placeholder="Search for anything" */}
            {/*  /> */}
            {/* </StyledSearchContainer> */}
          </StyledHeaderContent>
          <StyledDesktopContent>
            <p>{getDiscoverDesktopDescription(content)}</p>
          </StyledDesktopContent>
        </StyledHeader>
      </ResponsiveMaxWidthContent>
      <section>
        <DiscoverKeywords
          selectedTopic={selectedTopic}
          onSelect={setSelectedTopic}
          topics={topics.map(topic => topic.topic)}
        />
        <StyledResultsContainer>
          {filteredCoaches.map(coach => (
            <StyledCoachWrapper key={getCoachId(coach)}>
              <Link
                to={ROUTES.coach.paramPath(getCoachPreviewSlug(coach))}
                onClick={() => onCoachClick(coach.coachId)}
              >
                <StyledCoachImage image={getCoachImage(coach)}>
                  <StyledCoachPrice>
                    {isPlaceholder(coach) ? (
                      <p>Coming Soon</p>
                    ) : (
                      <PriceText
                        price={Number(getCoachPreviewPrice(coach))}
                        prefix="$"
                        suffix=" USD / month"
                      />
                    )}
                  </StyledCoachPrice>
                </StyledCoachImage>
                <StyledCoachName>{getCoachName(coach)}</StyledCoachName>
              </Link>
            </StyledCoachWrapper>
          ))}
          {filteredCoaches.length === 0 && (
            <StyledNoResultsCopy>
              <ReactMarkdown source={getDiscoverNoResultsCopy(content)} />
            </StyledNoResultsCopy>
          )}
        </StyledResultsContainer>
      </section>
      <StyledDesktopOnly>
        <StyledLogoWrapper>
          <img src="/svgs/youspiredBlack.svg" alt="Youspired Logo" />
        </StyledLogoWrapper>
      </StyledDesktopOnly>
      <YouspiredFooterCTA />
    </StyledContainer>
  )
}

export default DiscoverScreen
