import { graphql } from "gatsby"
import { get } from "lodash"
import { ICoachPreviewGraph } from "./coachPreview"
import { getEnvKey } from "../../utils/env"

export interface IDiscoverTopic {
  topic: string
  featuredCoaches?: ICoachPreviewGraph[]
  uatFeaturedCoaches?: ICoachPreviewGraph[]
}

export interface IDiscoverScreenGraph {
  desktopDescription: string
  filterTopics: IDiscoverTopic[]
  noResultsCopy: string
}

export const getTopicFeaturedCoaches = (
  topic: IDiscoverTopic
): ICoachPreviewGraph[] => {
  const key = getEnvKey({
    devKey: "featuredCoaches",
    stgKey: "featuredCoaches",
    uatKey: "uatFeaturedCoaches",
    prdKey: "featuredCoaches",
    defaultKey: "uatFeaturedCoaches",
  })
  return get(topic, key) || []
}

export const getDiscoverTopics = (
  data: IDiscoverScreenGraph
): IDiscoverTopic[] => {
  const topics = get(data, "filterTopics")
  if (!topics) return []
  return topics
}

export const getDiscoverDesktopDescription = (
  data: IDiscoverScreenGraph
): string => {
  return get(data, "desktopDescription", "") || ""
}

export const getDiscoverNoResultsCopy = (
  data: IDiscoverScreenGraph
): string => {
  return get(data, "noResultsCopy", "") || ""
}

export const query = graphql`
  fragment DiscoverScreenDev on DatoCmsWebDiscoverHomeScreenDev {
    desktopDescription
    filterTopics {
      topic
    }
    noResultsCopy
  }

  fragment DiscoverScreenStg on DatoCmsWebDiscoverHomeScreenStg {
    desktopDescription
    filterTopics {
      topic
    }
    noResultsCopy
  }

  fragment DiscoverScreenPrd on DatoCmsWebDiscoverHomeScreenPrd {
    desktopDescription
    filterTopics {
      topic
    }
    noResultsCopy
  }

  fragment DiscoverScreen on DatoCmsWebDiscoverHomeScreen {
    desktopDescription
    filterTopics {
      topic
    }
    noResultsCopy
  }
`
